<template>
  <div class="login-form fixed_center">
    <div class="title">{{ title }}</div>
    <p class="first_title">账号登录</p>
    <base-form
      :showBtns="false"
      ref="loginForm"
      :formAttrs="{
        model: formData,
        labelPosition: 'top',
        labelWidth: '100px',
      }"
      :componentList="componentList"
    ></base-form>
    <base-button label="登录"></base-button>
  </div>
</template>

<script>
import { login } from '@/api/sys/user'
import baseForm from '@/components/common/base-form//base-form.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
export default {
  components: { baseForm, BaseButton },
  data () {
    return {
      title: '稳诚一方供应链管理服务平台',
      formData: {
        account: '',
        password: '',
        userType: '3'
      },
      componentList: [
        {
          span: 24,
          label: '用户名',
          prop: 'account'
        },
        {
          span: 24,
          label: '密码',
          prop: 'account'
        }
      ]
    }
  },
  methods: {
    handleLogin: async function () {
      try {
        await this.validate()
        login(this.formData)
      } catch (error) {}
    },
    /**
     * 表单校验
     */
    validate: function () {
      return this.$refs.loginForm.validate()
    }
  }
}
</script>

<style lang="scss" scoped>
.login-form {
  width: 480px;
  height: 600px;
  background: #ffffff;
  box-shadow: 0px 10px 20px 0px rgba(239, 128, 44, 0.3);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 30px;
    font-style: italic;
    font-weight: bold;
  }
  .first_title {
    font-size: 28px;
    font-weight: 400;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      height: 7px;
      background: #2862E7;
    }
  }
}
</style>
